// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("expose-loader?$!jquery");
require('./libs/jquery-qrcode')
require('./libs/bootstrap-notify')
require("expose-loader?ClipboardJS!clipboard");

require("chartkick")
require("chart.js")

import 'typeface-roboto'
import 'bootstrap';

import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/solid";

window.DljBz = {};

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  var clipboard = new ClipboardJS('.btn');
  clipboard.on('success', function(e) {
    $.notify({
      message: '复制短链接为: ' + e.text
    },{
      placement: {
        from: "top",
        align: "center"
      },
      type: 'info'
    });
    e.clearSelection();
  });

  var clipboard = new ClipboardJS('.clipboardjs');
  clipboard.on('success', function(e) {
    $.notify({
      message: '复制: ' + e.text
    },{
      placement: {
        from: "top",
        align: "center"
      },
      type: 'info'
    });
    e.clearSelection();
  });

  // exportToCsv('export.csv', [
  //   ['name', 'description'],
  //   ['david', '123'],
  //   ['jona', '""'],
  //   ['a', 'b'],
  // ])
  DljBz.exportToCsv = function(filename, rows) {
    var processRow = function(row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        };
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0)
          finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], {
      type: 'text/csv;charset=utf-8;'
    });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  DljBz.notifyWarning = function(msg) {
    // http://bootstrap-notify.remabledesigns.com/
    $.notify({
      message: msg
    },{
      placement: {
        from: "top",
        align: "center"
      },
      type: 'warning'
    });
  }
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
